/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";
import Layout from "views/Layout/Layout.js";
import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import {Helmet} from "react-helmet";
import SectionContacts from "./Sections/SectionContacts.js";

const useStyles = makeStyles(contactUsStyle);

export default function Partners() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'Website',
      'name':'Partners',
      'description':'Interested parties are most welcome to join BUSLOC',
      'url':'www.busloc.io/partners',
      'keywords':'BUSLOC, join BUSLOC',
      'og:description':'Interested parties are most welcome to join BUSLOC',
      'og:title':'Partners - BUSLOC partners'
      
    },
  ];
  return (
    <Layout>
    <Helmet>
     <title>Partners - BUSLOC partners</title>
       <meta name="description" content="schedule a demo with BUSLOC to get a personal one to one meeting where BULOC Team will demonstrate the paltform features" />
      <meta name="keywords" content="BUSLOC, join BUSLOC" />
       {/* JSON-Ld tags*/}
       <script type="application/ld+json">
   {JSON.stringify(schemaOrgJSONLD)}
 </script>
      </Helmet>
     <div>

     <div className={classes.main}>
     <SectionContacts id="contacts" />
     </div>
     </div>
   
    
   </Layout>
  );
}
