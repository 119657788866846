import React from "react";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";

import city from "assets/img/examples/city.jpg";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import bg from "assets/img/svg/partners.svg";
import classNames from "classnames";
const useStyles = makeStyles(contactsStyle);


export default function SectionContacts({ ...rest }) {
  const [checked, setChecked] = React.useState([]);
  const [specialitySelect,setSpecialitySelect] = React.useState("0");
  const  handleSpeciality = event => {
    
    setSpecialitySelect(event.target.value)
  };
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const classes = useStyles();
  return (
    
    <div className={classes.aboutUs}>
     <br/><br/><br/>
     
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}   className={classNames(classes.mrAuto, classes.mlAuto)}>
              <h1 className={classNames(classes.title, classes.textCenter)} style={{fontSize:"30px",fontWeight:600,color:"#3C4858"}}>
              Want to work with us?
            </h1>
                <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6} lg={6}>
                 
                    <CardHeader image plain>
                      <br/>
                      <img src={bg} alt="..." />
                      {/* <a href="#pablito" onClick={e => e.preventDefault()}>
                      
                      </a> */}
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${bg})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} style={{paddingLeft:"25px",paddingRight:"10px"}}>
                    
                    <h4 className={classes.title} style={{fontWeight:400}}>
                     
                    We invite interested parties to join our team to implement BUSLOC for schools in US/Canada. 
                    <br/>We are particularly looking for
                   
                    </h4>
                   <ul className={classes.description}>
                     <li>Industry Consultants</li>
                     <li>School Districts / Schools</li>
                     <li>PTA bodies</li>
                     <li>BOCES</li>
                     <li>Day-care centers</li>
                     <li>Hardware Manufacturers</li>

                   </ul>
                    {/* <List style={{color:"#000000",fontWeight:500,fontSize:"16px"}}>
                     <ListItem>
                     
                     Industry Consultants
                     </ListItem>
                     <ListItem>
                    
                     School Authorities
                     </ListItem>
                     <ListItem>
                    
                     Parents sign-up and start receiving messages from Staff
                     </ListItem>
                     <ListItem>
                    
                    Parents sign-up and start receiving messages from Staff
                    </ListItem>
                    <ListItem>
                    
                    Parents sign-up and start receiving messages from Staff
                    </ListItem>
                    <ListItem>
                    
                    Parents sign-up and start receiving messages from Staff
                    </ListItem>
                 </List>
                   */}
                    
                  </GridItem>
                </GridContainer>
              </Card>
           
              <form id="partners" method="POST" 
                  action="https://formspree.io/xrgozzvv">
                  
           
              <GridContainer>
                <GridItem md={4} sm={4} style={{paddingLeft:"25px",paddingRight:"25px"}}>
                  <CustomInput name="name" id="name"
                    labelText="Your name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name:"name"
                    }}
                  />
                </GridItem>
                <GridItem md={4} sm={4} style={{paddingLeft:"25px",paddingRight:"25px"}}>
                  <CustomInput name="email" id="email"
                    labelText="Your email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name:"email",
                      required:true
                    }}
                  />
                </GridItem>
                <GridItem md={4} sm={4} style={{paddingLeft:"25px",paddingRight:"25px",paddingTop:"25px"}}>
                  <FormControl name="specialitySelect"
                    fullWidth
                    className={`${classes.selectFormControl} ${
                      classes.selectUnderlineRoot
                    }`}
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={specialitySelect}
                      onChange={handleSpeciality}
                      inputProps={{
                        name: "specialitySelect",
                        id: "speciality-select"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                        value="0"
                      >
                        I am
                      </MenuItem> 
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Industry Consultants"
                      >
                        Industry Consultants
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="School Districts / Schools"
                      >
                        School Districts / Schools
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="PTA bodies"
                      >
                        PTA bodies
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="BOCES"
                      >
                        BOCES
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Day-care centers"
                      >
                        Day-care centers
                      </MenuItem>
                     
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="Hardware Manufacturers"
                      >
                        Hardware Manufacturers
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem
                  md={4}
                  sm={4}
                  className={classNames(
                    classes.mrAuto,
                    
                    classes.textCenter
                  )}
                >
                    <input type="hidden" name="_subject" value="partner page request" />
                  <Button type="submit" value="Send" color="info" round>
                    Let's talk
                  </Button>
                </GridItem>
              </GridContainer>
            </form>
        
 </GridItem>
 <GridItem  md={3}>
 </GridItem>
 </GridContainer>
  </div>
  );
}
